import React, { useContext, useEffect, useState } from "react";
import "../Authentication.css";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for styling
import "react-phone-input-2/lib/style.css"; // Import the styles
import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
import Select from "react-select"; // Import react-select

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Success_Modal from "../../Common_Component/Common_Modal/Success_Modal/Success_Modal";
import { Context } from "../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import { RegxExpression } from "../../../utils/common";
import classNames from "classnames";

function Sign_Up_Modal(props) {
  const { postData, getData, Select2Data, setSignin, Cookies } = useContext(
    Context
  );

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
    watch,
  } = useForm({});

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [dob, setDob] = useState(null); // State for Date of Birth
  const [contactNumber, setContactNumber] = useState("");
  const [currency, setCurrency] = useState(null); // State for currency selection
  const [country, setCountry] = useState(null); // State for currency selection
  const [fileName, setFileName] = useState(""); // State for file name
  const [successShow, setSuccessShow] = React.useState(false);

  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();

  const handleHomeClick = () => {
    reset();
    props.onHide();
    navigate("/");
  };

  const handleSignupClick = () => {
    props.onHide();
    setSuccessShow(true);

    setTimeout(() => {
      setSuccessShow(false);
      navigate("/");
    }, 3000);
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  console.log("errors :- ", errors);

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("user_name", data?.user_name);
      DataToSend.append("first_name", data?.first_name);
      DataToSend.append("last_name", data?.last_name);
      DataToSend.append("dob", data?.dob);
      DataToSend.append("email", data?.email);
      DataToSend.append("contact_no", data?.contact_no);
      DataToSend.append("password", data?.password);
      DataToSend.append("country_id", data?.country_id?.value);
      DataToSend.append("currency_id", data?.currency_id?.value);
      DataToSend.append("image", data?.image?.[0]); // Ensure it's an array and pick the first file

      // Call the API (adjust this to your actual function for API calls)
      const response = await postData(`/auth/register`, DataToSend);

      if (response?.success) {
        // If registration is successful, show success and navigate
        await Cookies.set("betzone_security", response?.data?.token);
        setSignin(true);
        props.onHide();
        setSuccessShow(true);
        setTimeout(() => {
          setSuccessShow(false);
          navigate("/"); // Navigate to homepage or desired page
        }, 3000);
      } else {
        // If there are errors, iterate over them and set each one using setError
        if (
          response.message?.errors &&
          Array.isArray(response.message.errors)
        ) {
          response.message.errors.forEach((error) => {
            setError(error.path, {
              type: "manual",
              message: error.msg,
            });
          });
        } else {
          // Handle individual fields if they're in the message (e.g., username, email, etc.)
          if (response.message?.user_name) {
            setError("user_name", {
              type: "manual",
              message: response.message.user_name,
            });
          }
          if (response.message?.email) {
            setError("email", {
              type: "manual",
              message: response.message.email,
            });
          }
          if (response.message?.contact_no) {
            setError("contact_no", {
              type: "manual",
              message: response.message.contact_no,
            });
          }
        }
      }
    } catch (error) {
      console.log(error); // Log the error to the console for debugging
    }
  };

  const GetAllCountry = async () => {
    const response = await getData("/without-login/masters/country");

    if (response?.success) {
      setCountries(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllCourrency = async () => {
    const response = await getData("/without-login/masters/currency");

    if (response?.success) {
      setCurrencies(await Select2Data(response?.data, "currency_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    GetAllCourrency();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Login_Modal Common_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-bg-img">
          <img
            className="modal-bg"
            src={
              process.env.PUBLIC_URL +
              "/assets/Images/Lohin_Modal/warri-sports.png"
            }
            alt="modal-bg"
          />
        </div>
        <Modal.Body>
          <Link to={"/"}>
            <div className="home-icon-holder" onClick={handleHomeClick}>
              <FontAwesomeIcon className="home-icon" icon={faHouse} />
            </div>
          </Link>
          <div className="modal_heading">
            <h4>Sign Up</h4>
          </div>

          <div className="form-holder">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="form-field" controlId="">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Name"
                  {...register("user_name", {
                    required: "User Name Required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.user_name,
                  })}
                  onKeyDown={(event) => {
                    const allowedPattern = /^[A-Za-z0-9_-]$/;
                    if (
                      !allowedPattern.test(event.key) &&
                      event.key !== "Backspace"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
                {errors?.user_name && (
                  <sup className="text-danger">
                    {errors?.user_name?.message}
                  </sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter Password*"
                    name="password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message:
                          "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.password,
                      //   "is-valid": getValues("password"),
                    })}
                    // onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
                {errors?.password && (
                  <sup className="text-danger">{errors?.password?.message}</sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  {...register("first_name", {
                    required: "First Name Required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.first_name,
                  })}
                />
                {errors?.first_name && (
                  <sup className="text-danger">
                    {errors?.first_name?.message}
                  </sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  {...register("last_name", {
                    required: "Last Name Required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.last_name,
                  })}
                />
                {errors?.last_name && (
                  <sup className="text-danger">
                    {errors?.last_name?.message}
                  </sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="dob">
                <Form.Label>Date of Birth</Form.Label>
                <div className="input-icon-holder">
                  <Controller
                    name="dob"
                    readOnly
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "Date Of Birth is required",
                    }}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          {...field} // Spread the field properties for controlled input
                          selected={field.value}
                          maxDate={new Date()}
                          onChange={(date) => field.onChange(date)} // Handle date change
                          placeholderText="Enter Date of Birth"
                          className={classNames("form-control", {
                            "is-invalid": errors?.dob,
                          })}
                        />
                      </>
                    )}
                  />

                  <div className="calendar-icon">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </div>
                </div>
                {errors?.dob && (
                  <sup className="text-danger">{errors?.dob?.message}</sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: RegxExpression.email,
                      message: "Invalid email address",
                    },
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.email,
                    // "is-valid": getValues("email"),
                  })}
                />
                {errors?.email && (
                  <sup className="text-danger">{errors?.email?.message}</sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Contact</Form.Label>
                {/* <PhoneInput
                  country={"in"} // Default country
                  value={contactNumber}
                  onChange={setContactNumber}
                  inputStyle={{
                    width: "100%", // Full width
                    height: "40px", // Height of the input
                  }}
                  containerStyle={{
                    width: "100%", // Full width for the container
                  }}
                /> */}
                <Form.Control
                  type="text"
                  className={classNames("", {
                    "is-invalid": errors?.contact_no,
                    // "is-valid": isVerified,
                  })}
                  //   disabled={disabled}
                  name="contact_no"
                  placeholder="Enter Mobile Number"
                  {...register("contact_no", {
                    required: "Mobile Number is required",
                    minLength: {
                      value: 10,
                      message: "Number should be at least 10 characters",
                    },
                  })}
                  onKeyDown={(event) => {
                    if (!RegxExpression.phone.test(event.key)) {
                      if (event.key !== "Backspace") {
                        event.preventDefault();
                      }
                    }
                  }}
                  maxLength={10}
                />
                {errors?.contact_no && (
                  <sup className="text-danger">
                    {errors?.contact_no?.message}
                  </sup>
                )}
              </Form.Group>

              <Form.Group className="form-field file-text-field" controlId="">
                <Form.Label>
                  Uppload a clear & Whole front copy of your ID (optional)
                </Form.Label>
                <div className="file-field-holder">
                  {/* <Form.Control className="file-field" type="email" />
                  <Button className="common-btn">Browse</Button> */}
                  <Form.Control
                    className="file-field"
                    type="text"
                    placeholder={
                      watch("image")
                        ? watch("image")[0]?.name
                        : "No file selected"
                    }
                    readOnly // Make this field read-only
                  />
                  <Form.Control
                    type="file"
                    style={{ display: "none" }} // Hide the file input
                    id="fileInput" // Give an ID to the input for reference
                    className={classNames("", {
                      "is-invalid": errors?.image,
                    })}
                    {...register("image", {
                      required: "Image is required",
                      validate: async (value) => {
                        if (typeof value !== "string") {
                          const fileTypes = ["jpg", "png", "jpeg"];
                          const fileType = value[0].name?.split(".")[1];

                          if (!fileTypes.includes(fileType)) {
                            return `please upload a valid file format. (${fileTypes})`;
                          }
                        }
                      },
                    })}
                    accept=".jpg, .jpeg, .png"
                  />
                  <Button
                    className="common-btn"
                    onClick={() => document.getElementById("fileInput").click()} // Open file manager
                  >
                    Browse
                  </Button>
                </div>
                <span>Max. size of 8MB (JPG, JPEG, PNG)</span>
                <div>
                  {errors?.image && (
                    <sup className="text-danger">{errors?.image?.message}</sup>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Currency</Form.Label>
                <Controller
                  name="currency_id" // name of the field
                  {...register("currency_id", {
                    required: "Select Currency",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.currency_id ? "red" : baseStyles,
                        }),
                      }}
                      {...field}
                      options={currencies}
                    />
                  )}
                />
                {errors.currency_id && (
                  <span className="text-danger">
                    {errors.currency_id.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Country</Form.Label>
                <Controller
                  name="country_id" // name of the field
                  {...register("country_id", {
                    required: "Select Country",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.country_id ? "red" : baseStyles,
                        }),
                      }}
                      {...field}
                      options={countries}
                    />
                  )}
                />
                {errors.country_id && (
                  <span className="text-danger">
                    {errors.country_id.message}
                  </span>
                )}
              </Form.Group>
              <div className="btn-text-holder">
                <Button
                  className="common-btn"
                  // onClick={handleSignupClick}
                  type="submit"
                >
                  Sign Up
                </Button>

                <div className="create_account">
                  <div className="login_text">
                    <p>
                      I am at least 18 years old and have read and accepted the
                      <span className="login_text">Terms & Conditions.</span>
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Success_Modal
        text={"Sign In Successfully"}
        show={successShow}
        onHide={() => setSuccessShow(false)}
      />
    </>
  );
}

export default Sign_Up_Modal;
