import React, { useContext, useEffect, useState } from "react";
import "./Live_home.css";
import { Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import Overview from "./Overview/Overview";
import Event_details from "./Event_detals/Event_details";
import { getAllLive, getAllLiveEvents } from "../../../../utils/apis/games";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";

function Live_home() {
  const [allLive, setallLive] = useState({});
  const [eventDetailsId, seteventDetailsId] = useState();
  const [eventDetails, seteventDetails] = useState();

  const {
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      events: [],
    },
  });

  const getGames = async () => {
    const res = await getAllLiveEvents();
    setValue("events", res?.data);
    reset(getValues());
  };

  useEffect(() => {
    getGames();
    const intervalId = setInterval(() => {
      getGames();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const [active, setActive] = useState("first");
  return (
    <>
      <section className="Live_home">
        <div className="tab-holder">
          <Tab.Container id="left-tabs-example" activeKey={active}>
            <div className="row me-0 ms-0">
              <div className="col-md-12 p-lg-0 p-3">
                <Nav variant="pills" className="flex">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => {
                        setActive("first");
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="tab-icon"
                        icon={faClipboardList}
                      />
                      Overview
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => {
                        setActive("second");
                      }}
                    >
                      <FontAwesomeIcon
                        className="tab-icon"
                        icon={faCalendarDays}
                      />{" "}
                      Event Detail
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-12">
                <Tab.Content className="tab-content-holder">
                  <Tab.Pane eventKey="first">
                    <Overview
                      getValues={getValues}
                      setValue={setValue}
                      // allLive={allLive}
                      // seteventDetailsId={seteventDetailsId}
                      // seteventDetails={seteventDetails}
                      setActive={setActive}
                      reset={reset}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Event_details
                      getValues={getValues}
                      setValue={setValue}
                      eventDetailsId={eventDetailsId}
                      eventDetails={eventDetails}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </section>
    </>
  );
}

export default Live_home;
