import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import { Select2Data } from "./common";

export const Context = createContext();
var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();
const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:2999";
  // const IMG_URL = "http://192.168.16.53:2999/api";
  // const IMG_URL = "https://betzoneadmin.audiomeets.com/public/api";
  const IMG_URL = "https://warrisportsnode.profcymabackend.com:2999";

  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const [signin, setSignin] = useState(false);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [currentPrice, setCurrentPrice] = useState({});
  const minLength = 2;
  const maxLength = 30;

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const triggerCartUpdate = () => {
    setTrigger(trigger === 0 ? 1 : 0);
  };

  const getuserData = async (id) => {
    const response = await getData(`/auth/login/${id}`, { id: id });
    await setUserData(response?.data);
  };

  const isTokenValid = async () => {
    const token = Cookies.get("betzone_security");
    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;
      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("betzone_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        // setisAllow([]);
        navigate("/");
      } else {
        setUsertype(decodedToken.type);
        getuserData(decodedToken.user);
        setSignin(true);
      }
    } else {
      // setisAllow([]);
      setSignin(false);
      setUsertype("");
      navigate("/");
    }
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        cartCount,
        triggerCartUpdate,
        htmlToReactParser,
        Cookies,
        cart,
        setCart,
        Select2Data,
        currentPrice,
        setCurrentPrice,
        getuserData,
        isTokenValid,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
