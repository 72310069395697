import React from "react";
import "./Virtual_Modal.css";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Now_Play_modal from "../Now_Play_modal/Now_Play_modal";
import { useNavigate } from 'react-router-dom';

function Virtual_Modal(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const navigate = useNavigate();

  return (
    <>
      <section className="Virtual_Modal_holder">
        <Modal
          {...props}
          className="Virtual_Modal Game_Modal"
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="close-btn" onClick={props.onHide}>
              <FontAwesomeIcon className="close-icon" icon={faXmark} />
            </div>
            <div className="modal-content-holder">
              <div className="heading-holder">
                <h4>Virtual Football League - England</h4>
              </div>
              <div className="modal-btn-holder">
                <div className="btn-holder ">
                  <Button className="btn_text demo-btn">Demo</Button>
                </div>

                <div
                  className="btn-holder "
                  // onClick={() => {
                  //   setModalShow(true);
                  //   props.onHide();
                  // }}


                >
                  <Button className="btn_text nowPlay_btn" onClick={() => {navigate("/virtual_games")}}>Now Play</Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>

      <Now_Play_modal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Virtual_Modal;