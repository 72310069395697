import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import "./index.css";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import Home from "./Component/Home/Home";
import Virtual_routes from "./Component/Virtual-sport/Virtual-routes/Virtual_routes";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./Component/ScrollToTop/ScrollToTop";
import Exchange_layout from "./Component/Exchange/Exchange-layout/Exchange_layout";
import Live from "./Component/Live/Live";

import Virtual_games from "./Component/Virtual-sport/Virtual_games/Virtual_games";

function App() {
  const { pathname } = useLocation();
  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/virtual/*" element={<Virtual_routes />} />
        <Route path="/virtual_games" element={<Virtual_games />} />

        <Route path="/exchange/*" element={<Exchange_layout />} />
        <Route path="/live" element={<Live />} />
      </Routes>

      {pathname.startsWith("/virtual/") ||
      pathname.startsWith("/exchange/") ||
      pathname.startsWith("/live") ? null : (
        <Footer />
      )}
      {/* {pathname === "/virtual/*" || pathname === "/exchange/*"  ? ""  : <Footer />} */}
    </>
  );
}

export default App;
