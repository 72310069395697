import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  deleteCart,
  getAllCart,
  postPlaceBet,
} from "../../../../../../../utils/apis/games";
import { Context } from "../../../../../../../utils/context";
import Delete_Modal from "../../../../../../Common_Component/Common_Modal/Delete_Modal/Delete_Modal";
import Success_Modal from "../../../../../../Common_Component/Common_Modal/Success_Modal/Success_Modal";

function Live_Live_Center_my_Bet_Section() {
  const [modalShow, setModalShow] = React.useState(false);
  const [betModalShow, setBetModalShow] = React.useState(false);
  const [cart, setCart] = useState([]);
  const { signin, isTokenValid } = useContext(Context);

  const [betErrors, setBetErrors] = useState({});

  console.log(betErrors, "betError");

  const {
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({});

  const getCart = async () => {
    const res = await getAllCart();
    setCart(res?.data);
  };

  useEffect(() => {
    getCart();
    const intervalId = setInterval(() => {
      getCart();
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  const [stakeValues, setStakeValues] = useState({});

  const handleStakeChange = (e, id) => {
    const value = parseFloat(e.target.value) || 0;
    setStakeValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const deleteCarts = async (id) => {
    const response = await deleteCart(id);
    if (response?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        getCart();
      }, 2000);
    } else {
      console.error("Failed to Delete To Cart");
    }
  };

  const placeBet = async (
    event_id,
    market_id,
    market_name,
    odds,
    odd_id,
    amount,
    odd_name
  ) => {
    if (!amount || amount <= 0) {
      alert("Please Enter Stake Amount.");
      return;
    }

    const data = {
      event_id: event_id,
      market_id: market_id,
      market_name: market_name,
      odds: odds,
      odd_id: odd_id,
      amount: amount,
      odd_name: odd_name,
    };

    const response = await postPlaceBet(data);
    if (response?.success) {
      setBetModalShow(true);
      setTimeout(() => {
        isTokenValid();
        setBetModalShow(false);
        setStakeValues((prev) => ({
          ...prev,
          [odd_id]: "",
        }));
        setBetErrors((prev) => ({
          ...prev,
          [odd_id]: null,
        }));
      }, 2000);
    } else {
      setBetErrors((prev) => ({
        ...prev,
        [odd_id]: response?.message || "Failed to place bet.",
      }));
    }
  };

  return (
    <>
      <section className="Exchange_Live_Center_my_Bet_Section">
        <div className="My-Bets">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span className="main-text">My Bets</span>
              </Accordion.Header>
              <Accordion.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="orange-team">
                        <Accordion
                          defaultActiveKey={["0"]}
                          alwaysOpen
                          className="orange-team mt-2"
                        >
                          <Accordion.Item eventKey="0">
                            {signin && (
                              <>
                                <Accordion.Header>
                                  <div className="min-heading">
                                    <p className="title">
                                      Bets{" "}
                                      <span className="noofbets">
                                        {cart?.length}
                                      </span>
                                    </p>
                                    <div className="wrapper">
                                      <div className="corners corners--slanted"></div>
                                    </div>
                                  </div>
                                </Accordion.Header>

                                <Accordion.Body>
                                  <div className="orange-team-main first mb-3">
                                    {cart?.map((data) => (
                                      <div
                                        className="acmilandiv"
                                        key={data?.id}
                                      >
                                        <div className="delete-main">
                                          <p className="text">
                                            {data?.odd_name}
                                          </p>{" "}
                                          <div className="main-icon">
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                deleteCarts(data?.id)
                                              }
                                              icon={faTrash}
                                              className="icon-delete"
                                            />
                                          </div>
                                        </div>

                                        <p className="text">
                                          {" "}
                                          {data?.market_name}
                                        </p>
                                        <Link className="text">
                                          {data?.event?.opponent_a_name} vs.{" "}
                                          {data?.event?.opponent_b_name}
                                        </Link>
                                        <Row>
                                          <Col xs={9} className="pe-0">
                                            <Form.Label
                                              htmlFor={`stakeInput-${data?.id}`}
                                            >
                                              STAKE
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              id={`stakeInput-${data?.id}`}
                                              placeholder="Enter stake value"
                                              className="stakeinpp"
                                              onChange={(e) => {
                                                setBetErrors("");
                                                const value = e.target.value;
                                                if (
                                                  /^\d*\.?\d*$/.test(value) &&
                                                  value >= 0
                                                ) {
                                                  handleStakeChange(
                                                    e,
                                                    data?.id
                                                  );
                                                } else {
                                                  alert(
                                                    "Stake value must be a non-negative number"
                                                  );
                                                }
                                              }}
                                              value={
                                                stakeValues[data?.id] || ""
                                              }
                                              min="0"
                                            />
                                            <span className="egfefgfg">
                                              Returns
                                            </span>
                                          </Col>
                                          <Col xs={3} className="mt-auto">
                                            <Form.Label
                                              htmlFor={`stakeInput-${data?.id}`}
                                            />
                                            <button className="stvalebt">
                                              @ {data?.odds}
                                            </button>
                                            <span className="egfefgfg">
                                              RM
                                              {(
                                                stakeValues[data?.id] *
                                                  data?.odds || 0
                                              ).toFixed(2)}
                                            </span>
                                            {signin && (
                                              <button
                                                onClick={() =>
                                                  placeBet(
                                                    data?.event_id,
                                                    data?.market_id,
                                                    data?.market_name,
                                                    data?.odds,
                                                    data?.odd_id,
                                                    stakeValues[data?.id],
                                                    data?.odd_name
                                                  )
                                                }
                                              >
                                                <h6>Place Bet</h6>
                                              </button>
                                            )}
                                            {betErrors[data?.odd_id] && (
                                              <p
                                                className="error-text"
                                                style={{ color: "red" }}
                                              >
                                                {betErrors[data?.odd_id]}
                                              </p>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </div>
                                </Accordion.Body>
                              </>
                            )}
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>

      <Delete_Modal
        text={"Cart Deleated Successfully"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Success_Modal
        text={"Bet Placed Successfully"}
        show={betModalShow}
        onHide={() => setBetModalShow(false)}
      />
    </>
  );
}

export default Live_Live_Center_my_Bet_Section;
