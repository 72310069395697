import React, { useContext, useEffect, useState } from "react";
import "../Authentication.css";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faHouse } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Reset_Password from "../Reset_Password/Reset_Password";
import Sign_Up_Modal from "../Sign_Up_Modal/Sign_Up_Modal";
import Success_Modal from "../../Common_Component/Common_Modal/Success_Modal/Success_Modal";
import { Context } from "../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import { RegxExpression } from "../../../utils/common";
import classNames from "classnames";

function Login_Modal(props) {
  const { postData, getData, Select2Data, setSignin, Cookies } = useContext(
    Context
  );

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
    watch,
  } = useForm({});

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [forgetModalShow, setForgetModalShow] = React.useState(false);
  const [signUpModalShow, setSignUpModalShow] = React.useState(false);
  const [successShow, setSuccessShow] = React.useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();

  const handleHomeClick = () => {
    props.onHide();
    navigate("/");
  };

  const handleLoginClick = () => {
    props.onHide();
    setSuccessShow(true);

    // Set timeout to close the modal and navigate after 3 seconds
    setTimeout(() => {
      setSuccessShow(false);
      navigate("/");
    }, 3000);
  };

  const handleForgotPasswordClick = () => {
    props.onHide();
    setForgetModalShow(true);
  };

  const handleSignUpClick = () => {
    props.onHide();
    setSignUpModalShow(true);
  };

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("user_name", data?.user_name);
      DataToSend.append("password", data?.password);
      const response = await postData(`/auth/login`, DataToSend);

      if (response?.success) {
        await Cookies.set("betzone_security", response?.data?.token);

        reset();
        clearErrors();
        setSignin(true);
        props.onHide();
        setSuccessShow(true);
        setTimeout(() => {
          setSuccessShow(false);
          navigate("/");
        }, 3000);
      } else {
        // If there are errors, iterate over them and set each one using setError
        if (
          response.message?.errors &&
          Array.isArray(response.message.errors)
        ) {
          response.message.errors.forEach((error) => {
            setError(error.path, {
              type: "manual",
              message: error.msg,
            });
          });
        } else {
          // Handle individual fields if they're in the message (e.g., username, email, etc.)
          if (response.message?.user_name) {
            setError("user_name", {
              type: "manual",
              message: response.message.user_name,
            });
          }
          if (response.message?.password) {
            setError("password", {
              type: "manual",
              message: response.message.password,
            });
          }
          if (response.message?.contact_no) {
            setError("contact_no", {
              type: "manual",
              message: response.message.contact_no,
            });
          }
        }
      }
    } catch (error) {
      console.log(error); // Log the error to the console for debugging
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Login_Modal Common_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-bg-img">
          <img
            className="modal-bg"
            src={
              process.env.PUBLIC_URL +
              "/assets/Images/Lohin_Modal/warri-sports.png"
            }
            alt="modal-bg"
          />
        </div>
        <Modal.Body>
          <Link to={"/"}>
            <div className="home-icon-holder" onClick={handleHomeClick}>
              <FontAwesomeIcon className="home-icon" icon={faHouse} />
            </div>
          </Link>
          <div className="modal_heading">
            <h4>Log In</h4>
          </div>

          <div className="form-holder">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="form-field" controlId="">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Name"
                  {...register("user_name", {
                    required: "User Name Required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.user_name,
                  })}
                  onKeyDown={(event) => {
                    const allowedPattern = /^[A-Za-z0-9_-]$/;
                    if (
                      !allowedPattern.test(event.key) &&
                      event.key !== "Backspace"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
                {errors?.user_name && (
                  <sup className="text-danger">
                    {errors?.user_name?.message}
                  </sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter Password*"
                    name="password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message:
                          "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.password,
                      //   "is-valid": getValues("password"),
                    })}
                    // onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
                {errors?.password && (
                  <sup className="text-danger">{errors?.password?.message}</sup>
                )}
              </Form.Group>

              <div
                className="Forgot_password"
                onClick={handleForgotPasswordClick}
              >
                Forgot password?
              </div>

              <Button
                className="common-btn"
                // onClick={handleLoginClick}
                type="submit"
              >
                Log In
              </Button>
            </Form>

            <div className="create_account">
              <div className="login_text">
                <p>
                  Don't have an account ?
                  <span className="login_text" onClick={handleSignUpClick}>
                    Create account
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Reset_Password
        show={forgetModalShow}
        onHide={() => setForgetModalShow(false)}
        loginModal={props.loginModal}
        resetModal={() => setForgetModalShow(true)}
      />

      <Sign_Up_Modal
        show={signUpModalShow}
        onHide={() => setSignUpModalShow(false)}
      />

      <Success_Modal
        text={"Log in successfully"}
        show={successShow}
        onHide={() => setSuccessShow(false)}
      />
    </>
  );
}

export default Login_Modal;
