import React, { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFutbol, faHouse, faShirt } from "@fortawesome/free-solid-svg-icons";
import Live_GraphTab from "../../Live_GraphTab";
import Live_Probability from "../../Live_Probability";
import { faStopwatch20 } from "@fortawesome/free-solid-svg-icons";
import Live_VoiceTab from "../../Live_VoiceTab";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Accordion from "react-bootstrap/Accordion";

const ProgressBar = ({ percent, color, vertical }) => {
  const progressStyle = {
    height: vertical ? `${percent}%` : "100%",
    width: vertical ? "100%" : `${percent}%`,
  };

  return (
    <div className={`progress-bar ${color}`}>
      <div className="progress-fill" style={progressStyle} />
    </div>
  );
};

function Live_Live_Center_tab_Section() {
  const items = [
    { key: "FC Midtjylland", label: "FK Rigas Futbola Skola" },
    { key: "Another action", label: "FC Midtjylland" },
    { key: "Sparta Prague", label: "Sparta Prague" },
    { key: "FC Midtjylland", label: "FK Rigas Futbola Skola" },
    { key: "Another action", label: "FC Midtjylland" },
    { key: "Sparta Prague", label: "Sparta Prague" },
    { key: "FC Midtjylland", label: "FK Rigas Futbola Skola" },
    { key: "Another action", label: "FC Midtjylland" },
    { key: "Sparta Prague", label: "Sparta Prague" },
  ];

  const [selectedItem, setSelectedItem] = useState(items[0]);

  const handleSelect = (eventKey) => {
    const item = items.find((i) => i.key === eventKey);
    setSelectedItem(item);
  };

  const vidRef = useRef();

  useEffect(() => {
    vidRef.current.play();
  }, []);



  const leftPercentage = 100;
  const rightPercentage = 63;

  // timer
  const [time2, setTime2] = React.useState(32);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTime2((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = `0:${time2.toString().padStart(2, "0")}`;

  const data = [
    {
      uv: 20,
      pv: 10,
      amt: 2210,
    },
  ];



  const leftPercentage2 = 90;
  const rightPercentage2 = 10;

  const [percent, setPercent] = React.useState(30);

  const decline = () => {
    const value = Math.max(percent - 10, 0);
    setPercent(value);
  };

  const increase = () => {
    const value = Math.min(percent + 10, 100);
    setPercent(value);
  };


  return (
    <>
      <section className="Exchange_Live_Center_tab_Section">
        <div className="Tab-section">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="main-sec me-0 ms-0">
              <Col xxl={4} xl={4} lg={12} md={4}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Exchange/livecenter/comp1.png"
                        }
                        className="logo"
                        alt="Component 1"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Exchange/livecenter/comp2.png"
                        }
                        className="logo"
                        alt="Component 2"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Exchange/livecenter/comp3.png"
                        }
                        className="logo"
                        alt="Component 3"
                      />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xxl={8} xl={8} lg={12} md={8}>
                <div className="dropdown-main">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="custom-dropdown-toggle"
                    >
                      <div className="names-drop">
                        <FontAwesomeIcon icon={faFutbol} />
                        <p className="text">{selectedItem.label}</p>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="scrollbar" id="style-3">
                        {items.map((item) => (
                          <Dropdown.Item key={item.key} eventKey={item.key}>
                            <div className="names-drop">
                              <FontAwesomeIcon icon={faFutbol} />
                              <p className="text">{item.label}</p>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="main-video">
                  <video
                    src="/assets/Images/Exchange/livecenter/vedio.mp4"
                    ref={vidRef}
                    muted
                    loop
                    className="video-class-grid"
                    controls
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div className="main-video">
                  <video
                    src="/assets/Images/Exchange/livecenter/vedio.mp4"
                    ref={vidRef}
                    muted
                    loop
                    className="video-class-grid"
                    controls
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                <div className="graph">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/Icon-ionic-md-stats.png"
                            }
                            className="logo"
                            alt="Component 1"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/heat-to-head.png"
                            }
                            className="logo"
                            alt="Component 1"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/Icon-awesome-microphone.png"
                            }
                            className="logo"
                            alt="Component 1"
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content>

                      {/* tab 1--------------------------------------------------------------------------------------------------------- */}
                      <Tab.Pane eventKey="first">
                        {/* <Live_GraphTab /> */}


                        <section className="GraphTab">
                          <Swiper
                            pagination={{
                              type: "fraction",
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                          >
                            <SwiperSlide>
                              <p className="card-no main">STATISTICS</p>
                              <div className="cards-section">
                                <div className="main-card">
                                  <p className="card-no">0</p>
                                  <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/cards.png"}
                                    className="logo"
                                    alt="Component 3"
                                  />
                                  <p className="card-no">0</p>
                                </div>
                                <div className="main-card-two">
                                  <p className="card-no main">CARDS</p>
                                </div>
                                <div className="main-card">
                                  <p className="card-no">0</p>
                                  <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/cards.png"}
                                    className="logo"
                                    alt="Component 3"
                                  />
                                  <p className="card-no">0</p>
                                </div>
                              </div>
                              <div className="range-bar">
                                <p className="kicks">CORNER KICKS</p>

                                <div className="range-bar-container">
                                  <div className="percentage-text">{leftPercentage}%</div>
                                  <div className="bar-container">
                                    <div className="bar left-bar" style={{ width: `${leftPercentage}%`, marginRight: "4px" }} />
                                    <div className="bar right-bar" style={{ width: `${rightPercentage}%` }} />
                                  </div>
                                  <div className="percentage-text">{rightPercentage}%</div>
                                </div>
                              </div>
                            </SwiperSlide>

                            <SwiperSlide>
                              <p className="card-no main">STATISTICS</p>
                              <div className="range-bar">
                                <p className="kicks">TIME IN DANGEROUS ATTACK</p>
                                <div className="range-bar-container">
                                  <div className="percentage-text">{leftPercentage}%</div>
                                  <div className="bar-container">
                                    <div
                                      className="bar left-bar"
                                      style={{ width: `${leftPercentage}%`, marginRight: "4px" }}
                                    />
                                    <div className="bar right-bar" style={{ width: `${rightPercentage}%` }} />
                                  </div>
                                  <div className="percentage-text">{rightPercentage}%</div>
                                </div>
                                <div className="timer-main">
                                  <div className="timer">
                                    <span className="stop-watch">
                                      <FontAwesomeIcon icon={faStopwatch20} className="icon-watch me-1" />
                                      <div className="watch">{formattedTime}</div>
                                    </span>
                                    <p className="kicks watch">AVG MIN / ATTACK</p>
                                  </div>
                                  <div className="main">
                                    <div className="graph-range">
                                      <p className="kick">22</p>
                                      <BarChart
                                        width={70}
                                        height={80}
                                        data={data}
                                        margin={{
                                          top: 20,
                                          right: 30,
                                          left: 20,
                                          bottom: 5,
                                        }}
                                      >
                                        <XAxis dataKey="name" />

                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="pv" fill="#8884d8" />
                                        <Bar dataKey="uv" fill="#82ca9d" />
                                      </BarChart>
                                      <p className="kick">50</p>
                                    </div>
                                    <p className="kick range-main">DANGEROUS ATTACK</p>
                                  </div>

                                  <div className="timer two">
                                    <span className="stop-watch">
                                      <FontAwesomeIcon icon={faStopwatch20} className="icon-watch me-1" />
                                      <div className="watch">{formattedTime}</div>
                                    </span>
                                    <p className="kicks watch">AVG MIN / ATTACK</p>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>

                            <SwiperSlide>
                              <p className="card-no main">STATISTICS</p>
                              <div className="range-bar">
                                <p className="kicks">SUBSTITUTIONS</p>
                                <div className="range-bar-container">
                                  <div className="percentage-text">{leftPercentage}</div>
                                  <div className="bar-container">
                                    <div
                                      className="bar left-bar"
                                      style={{ width: `${leftPercentage}%`, marginRight: "4px" }}
                                    />
                                    <div className="bar right-bar" style={{ width: `${rightPercentage}%` }} />
                                  </div>
                                  <div className="percentage-text">{rightPercentage}</div>
                                </div>
                                <div className="third-range">
                                  <p className="kicks">INJURIES</p>
                                  <div className="range-bar-container">
                                    <div className="percentage-text">{leftPercentage}</div>
                                    <div className="bar-container">
                                      <div
                                        className="bar left-bar"
                                        style={{ width: `${leftPercentage}%`, marginRight: "4px" }}
                                      />
                                      {/* <div className="bar right-bar" style={{width: `${rightPercentage}%`}} /> */}
                                    </div>
                                    <div className="percentage-text">{rightPercentage}</div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>

                          </Swiper>
                        </section>

                      </Tab.Pane>

                      {/* tab 2--------------------------------------------------------------------------------------------------------- */}
                      <Tab.Pane eventKey="second">
                        {/* <Live_Probability /> */}
                        <section className="GraphTab">
                          <Swiper
                            pagination={{
                              type: "fraction",
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                          >
                            <SwiperSlide>
                              <p className="card-no main">HEAD TO HEAD</p>

                              <div className="range-bar">
                                <p className="kicks">WIN PROBABILITY</p>
                                <p className="withdraw">8% DRAW</p>
                                <div className="range-bar-container">
                                  <div className="percentage-text blue">
                                    {leftPercentage2}% <p>FCM</p>
                                  </div>
                                  <div className="bar-container">
                                    <div
                                      className="bar left-bar"
                                      style={{ width: `${leftPercentage2}%`, marginRight: "4px" }}
                                    />
                                    <div className="bar right-bar" style={{ width: `${rightPercentage2}%` }} />
                                  </div>
                                  <div className="percentage-text green">
                                    {rightPercentage2}%<p>VIL</p>{" "}
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <p className="card-no main">HEAD TO HEAD</p>
                              <Row>
                                <Col xxl={4} xl={12} lg={12} className="mt-4">
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box yellow">
                                          <p className="wicket">D</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">INT</p>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box green">
                                          <p className="wicket">L</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">DEL</p>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box blue">
                                          <p className="wicket">W</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">LAJ</p>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box yellow">
                                          <p className="wicket">D</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">INT</p>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box yellow">
                                          <p className="wicket">D</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">REAL</p>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xxl={4} xl={12} lg={12}>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4} className="mt-4">
                                      <p className="point">1:1</p>
                                      <p className="point">1:3</p>
                                      <p className="point">1:1</p>
                                      <p className="point">1:0</p>
                                      <p className="point">1:2</p>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="point">FORM</p>
                                      <div className="progress-team">
                                        <div className="progress-wrapper">
                                          <div className="blue-team">
                                            <ProgressBar percent={40} color="blue" vertical={true} />
                                          </div>
                                          <p className="percentage-text blue">40%</p>
                                        </div>
                                        <div className="progress-wrapper">
                                          <div className="green-team">
                                            <ProgressBar percent={47} color="green" vertical={true} />
                                          </div>
                                          <p className="percentage-text green">47%</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4} className="mt-4">
                                      <p className="point">3:0</p>
                                      <p className="point">4:0</p>
                                      <p className="point">0:4</p>
                                      <p className="point">2:1</p>
                                      <p className="point">0:0</p>
                                    </Col>
                                  </Row>
                                  <div className="Points-graph"></div>
                                </Col>
                                <Col xxl={4} xl={12} lg={12} className="mt-4">
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">INT</p>
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box green">
                                          <p className="wicket">W</p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">DEL</p>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box green">
                                          <p className="wicket">W</p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">LAJ</p>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box red">
                                          <p className="wicket">L</p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">INT</p>
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box red">
                                          <p className="wicket">L</p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <p className="name">REAL</p>
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4}>
                                      <div className="main-box">
                                        <div className="box yellow">
                                          <p className="wicket">D</p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </SwiperSlide>
                          </Swiper>
                        </section>
                      </Tab.Pane>


                      {/* tab 3--------------------------------------------------------------------------------------------------------- */}
                      <Tab.Pane eventKey="third">
                        {/* <Live_VoiceTab /> */}

                        <section className="VoiceTab">
                          <p className="title mt-1">TIMELINE</p>
                          <Accordion className="mt-2">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <div className="main-text">
                                  <p className="text">2nd Half</p>
                                  <p className="text">1 - 0</p>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="end-game">
                                  <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/shoes.png"}
                                    className="logo me-2"
                                  />
                                  <p className="text">End of Game</p>
                                </div>
                                <div className="full-time">
                                  <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/shoes.png"}
                                    className="logo me-2"
                                  />
                                  <p className="text">Full Time</p>
                                </div>
                                <div className="scroller">
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={3} xl={4} lg={4}>
                                        <p className="game-text">2'</p>
                                        <p className="game-text">Throw-in</p>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                          <p className="game-text bold">ANT</p>
                                        </div>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/arrow.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={4} xl={4} lg={4}>
                                        <p className="game-text">3'</p>
                                        <p className="game-text">Corner Kick</p>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                          <p className="game-text bold">RBL</p>
                                        </div>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/angle.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={4} xl={4} lg={4}>
                                        <p className="game-text">3'</p>
                                        <p className="game-text">Corner Kick</p>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                          <p className="game-text bold">RBL</p>
                                        </div>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/angle.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={4} xl={4} lg={4}>
                                        <p className="game-text">2'</p>
                                        <p className="game-text">Throw-in</p>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                          <p className="game-text bold">ANT</p>
                                        </div>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/arrow.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div className="full-time">
                                  <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/shoes.png"}
                                    className="logo me-2"
                                  />
                                  <p className="text">2nd Half</p>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="mt-1">
                              <Accordion.Header>
                                <div className="main-text">
                                  <p className="text">1st Half</p>
                                  <p className="text">1 - 0</p>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="scroller">
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={4} xl={4} lg={4}>
                                        <p className="game-text">2'</p>
                                        <p className="game-text">Throw-in</p>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                          <p className="game-text bold">ANT</p>
                                        </div>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/arrow.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={4} xl={4} lg={4}>
                                        <p className="game-text">3'</p>
                                        <p className="game-text">Corner Kick</p>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                          <p className="game-text bold">RBL</p>
                                        </div>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/angle.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={4} xl={4} lg={4}>
                                        <p className="game-text">3'</p>
                                        <p className="game-text">Corner Kick</p>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                          <p className="game-text bold">RBL</p>
                                        </div>
                                      </Col>
                                      <Col xxl={4} xl={4} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/angle.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="game-main">
                                    <Row className="score">
                                      <Col xxl={3} xl={3} lg={4}>
                                        <p className="game-text">2'</p>
                                        <p className="game-text">Throw-in</p>
                                      </Col>
                                      <Col xxl={2} xl={2} lg={4}>
                                        <div className="team-t-shirt">
                                          <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                          <p className="game-text bold">ANT</p>
                                        </div>
                                      </Col>
                                      <Col xxl={2} xl={2} lg={4}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/arrow.png"
                                          }
                                          className="logo me-2"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>

                        </section>
                      </Tab.Pane>


                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
}

export default Live_Live_Center_tab_Section;
