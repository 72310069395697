import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import "./ExchangeLayoutMain.css";

import Exchange_sidebar from "./Exchange-sidebar/Exchange_sidebar";
import Exchange_bet_sidebar from "./Exchange-bet-sidebar/Exchange_bet_sidebar";
import Exchange_routes from "../Exchange-routes/Exchange_routes";
import Mob_bot_head from "../../Header/Mob-view-bot-head/Mob_bot_head";

const Exchange_layout = () => {
  const location = useLocation();

  // Check if the current route is /exchange/profile or /exchange/history
  const isProfileOrHistory =
    location.pathname === "/exchange/account-statement" ||
    location.pathname === "/exchange/bet-history" ||
    location.pathname === "/exchange/unsetteled-bet" ||
    location.pathname === "/exchange/set-button-values" ||
    location.pathname === "/exchange/security-auth-verification" ||
    location.pathname === "/exchange/change-password" ||
    location.pathname === "/exchange/profit-loss-report" ||
    location.pathname === "/exchange/account-details";

  return (
    <>
      <section className="ex-home-main-section">
        <div className="container-fluid ">
          <div className="row">
            {/* <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-6 p-0 order-2 order-md-1 exchnagefirstbar">
              <div className="ex-sidebar">
                <Exchange_sidebar />
              </div>
            </div> */}

            <div
              // className={`col-xxl-${isProfileOrHistory ? 10 : 7}  col-lg-${
              //   isProfileOrHistory ? 10 : 7
              // }  col-sm-12 order-1 order-md-2 p-0`}

              className="col-12"

            >
              <div className="ex-mid-div">
                <Exchange_routes />
              </div>

              <Mob_bot_head />
            </div>

            {!isProfileOrHistory && (
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 p-0 order-3 order-md-3 exchnagelastbar">
                <div className="ex-last-div">
                  <Exchange_bet_sidebar />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Exchange_layout;
