import React, { useContext, useEffect, useRef, useState } from "react";
import Exchange_bet_sidebar from "../Exchange/Exchange-layout/Exchange-bet-sidebar/Exchange_bet_sidebar";
import Live_breadcrumb from "./Live_components/Live_breadcrumb/Live_breadcrumb";
import Live_bet_sidebar from "./Live_components/Live_home/Live-bet-sidebar/Live_bet_sidebar";
import Live_home from "./Live_components/Live_home/Live_home";
import { Accordion, Nav, Tab, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  addtoCart,
  getAllLiveEvents,
  getEventMarket,
  getSingleEventOfCustomer,
} from "../../utils/apis/games";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faClipboardList,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Overview from "./Live_components/Live_home/Overview/Overview";
import Event_details from "./Live_components/Live_home/Event_detals/Event_details";
import Overview_card from "./Live_components/Live_home/Overview/Overview_card/Overview_card";
import { FormateDateTime, status } from "../../utils/common";
import Event_details_card from "./Live_components/Live_home/Event_detals/Event_details_card/Event_details_card";
import { Context } from "../../utils/context";
import Success_Modal from "../Common_Component/Common_Modal/Success_Modal/Success_Modal";
import Login_Modal from "../Authentication/Login_Modal/Login_Modal";
import Live_Live_Center_tab_Section from "./Live_components/Live_home/Live-bet-sidebar/Live_Live_Center/Live_Live_Center_tab_Section/Live_Live_Center_tab_Section";
import Live_Live_Center_my_Bet_Section from "./Live_components/Live_home/Live-bet-sidebar/Live_Live_Center/Live_Live_Center_my_Bet_Section/Live_Live_Center_my_Bet_Section";

function Live() {
  const [allLive, setallLive] = useState({});
  const [eventDetailsId, seteventDetailsId] = useState();
  const [eventDetails, seteventDetails] = useState();

  const {
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      events: [],
    },
  });

  const getGames = async () => {
    const res = await getAllLiveEvents();
    setValue("events", res?.data);
    reset(getValues());
  };

  useEffect(() => {
    getGames();
    const intervalId = setInterval(() => {
      getGames();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const [active, setActive] = useState("first");

  const [market, setMarket] = useState([]);

  const [event, setEvent] = useState({});
  const [allEvent, setAllEvent] = useState({});
  const eventId = getValues("event_id");

  const fetchMarketsAndEvent = async (id) => {
    if (!id) return;

    try {
      const marketData = await getEventMarket(id);
      setMarket(marketData?.data || []);

      // const eventData = await getEvent(id);
      // setEvent(eventData?.data || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllEvents = async () => {
    try {
      const allEventData = await getAllLiveEvents();

      console.log(allEventData, "allEventData");

      setAllEvent(allEventData?.data || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [loginShow, setLoginShow] = React.useState(false);

  console.log(eventId, "eventId");

  const [eventData, setEventData] = React.useState("");
  console.log(eventData, "eventData");

  const [time, setTime] = useState(event?.match_time || "0:0");
  const timerRef = useRef(null);

  const { setCurrentPrice, currentPrice, signin } = useContext(Context);

  useEffect(() => {
    setCurrentPrice(
      market?.map((value) => value?.outcomes?.map((outcome) => outcome))
    );

    // setCurrentPrice(market);
    let [initialMinutes, initialSeconds] = event?.match_time
      ? event.match_time.split(":").map(Number)
      : [0, 0];
    let totalSeconds = initialMinutes * 60 + initialSeconds;

    const intervalId = setInterval(() => {
      totalSeconds++;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      setTime(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [event?.match_time]);

  const addToCart = async (
    event_id,
    market_id,
    market_name,
    odd_id,
    odd_name,
    odds
  ) => {
    const data = {
      event_id: event_id,
      market_id: market_id,
      market_name: market_name,
      odd_id: odd_id,
      odd_name: odd_name,
      odds: odds,
    };

    const response = await addtoCart(data);

    if (response?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
      }, 2000);
    } else {
      console.error("Failed to Add To Cart");
    }
  };

  const getSingleEvent = async () => {
    try {
      const response = await getSingleEventOfCustomer(eventId);
      console.log("API Response:", response);
      setEventData(response?.data);
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  useEffect(() => {
    getSingleEvent();
  }, [eventId]);

  useEffect(() => {
    fetchAllEvents();
    const eventId = getValues("event_id");

    if (eventId) {
      fetchMarketsAndEvent(eventId);

      const intervalId = setInterval(() => {
        fetchMarketsAndEvent(eventId);
        getSingleEvent();
      }, 5000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [getValues("event_id")]);

  return (
    <>
      <section className="Live">
        <div className="container-fluid p-0">
          <div className="row me-0 ms-0">
            <div className="col-xxl-9 col-xl-9 col-lg-9   p-0 order-1 order-md-1 ">
              <div className="live-content-holder">
                <Live_breadcrumb />

                {/* Old Component */}
                {/* <Live_home /> */}

                <section className="Live_home">
                  <div className="tab-holder">
                    <Tab.Container id="left-tabs-example" activeKey={active}>
                      <div className="row me-0 ms-0">
                        <div className="col-md-12 p-lg-0 p-3">
                          <Nav variant="pills" className="flex">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="first"
                                onClick={() => {
                                  setActive("first");
                                }}
                              >
                                {" "}
                                <FontAwesomeIcon
                                  className="tab-icon"
                                  icon={faClipboardList}
                                />
                                Overview
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="second"
                                onClick={() => {
                                  setActive("second");
                                }}
                              >
                                <FontAwesomeIcon
                                  className="tab-icon"
                                  icon={faCalendarDays}
                                />{" "}
                                Event Detail
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                        <div className="col-md-12">
                          <Tab.Content className="tab-content-holder">
                            <Tab.Pane eventKey="first">
                              {/* Old Component -------------------------------------------------------------------------------------------------------------- */}

                              {/* <Overview
                                getValues={getValues}
                                setValue={setValue}
                                // allLive={allLive}
                                // seteventDetailsId={seteventDetailsId}
                                // seteventDetails={seteventDetails}
                                setActive={setActive}
                                reset={reset}
                              /> */}
                              {/* Old Component end-------------------------------------------------------------------------------------------------------------- */}

                              <section className="Overview">
                                {getValues("events")?.live?.length > 0 ? (
                                  getValues("events")?.live?.map(
                                    (event, index) => {
                                      return (
                                        <div
                                          className="Overview_card_content_holder mb-4"
                                          key={index}
                                        >
                                          {/* Old Component -------------------------------------------------------------------------------------------------------------- */}

                                          {/* <Overview_card
                                          event={event}
                                          getValues={getValues}
                                          setValue={setValue}
                                          // seteventDetailsId={seteventDetailsId}
                                          // seteventDetails={seteventDetails}
                                          setActive={setActive}
                                          reset={reset}
                                        /> */}

                                          {/* Old Component end-------------------------------------------------------------------------------------------------------------- */}

                                          <section className="Overview_card">
                                            <div className="Overview_card_holder">
                                              <div className="table-container">
                                                <Table
                                                  responsive
                                                  className="match-table mb-0"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        colSpan="2"
                                                        className="text-start"
                                                      >
                                                        {event?.event_name}
                                                      </th>

                                                      <th className="text-center"></th>
                                                      <th className="text-center"></th>
                                                      <th className="text-center"></th>
                                                      <th className="text-center">
                                                        {event?.id}
                                                      </th>
                                                      <th className="text-center"></th>
                                                      <th
                                                        className="text-center btn"
                                                        onClick={async () => {
                                                          await setActive(
                                                            "second"
                                                          );
                                                          // await setValue("event", event);
                                                          await setValue(
                                                            "event_id",
                                                            event?.id
                                                          );

                                                          reset(getValues());
                                                          // await seteventDetails(event);
                                                          // await seteventDetailsId(event?.id);
                                                          // console.log(event?.id);
                                                        }}
                                                      >
                                                        {event?.status?.replace(
                                                          /_/g,
                                                          " "
                                                        )}
                                                        {/* {status.notstarted ===
                                                        event.status
                                                          ? "Upcomming Event"
                                                          : "Event Details"} */}
                                                      </th>
                                                      <th className="text-center">
                                                        <FontAwesomeIcon
                                                          className="start-icon"
                                                          icon={faStar}
                                                        />
                                                      </th>
                                                    </tr>
                                                  </thead>

                                                  {/* <tbody>
                                                  {market?.map((val, index) => (
                                                    <tr>
                                                      <td className="time-cell">
                                                        {event?.clock?.minutes}:{event?.clock?.seconds}
                                                      </td>
                                                      <td>{val?.description}</td>
                                                      <td className="text-center">
                                                        <FontAwesomeIcon className="play-icon" icon={faPlay} />
                                                      </td>
                                                      <td className="text-center number-box active">1.25</td>
                                                      {val?.outcomes?.map((outcome, index) => (
                                                        <td className="text-center number-box" key={index}>
                                                          {outcome?.consolidatedPrice?.currentPrice?.format}
                                                        </td>
                                                      ))}

                                                      <td className="text-center">0</td>
                                                      <td className="text-center">
                                                        <FontAwesomeIcon className="start-icon" icon={faStar} />
                                                      </td>
                                                    </tr>
                                                  ))}

                                                </tbody> */}
                                                </Table>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <h1>No Live Match Going On</h1>
                                )}

                                {/* Upcoming */}
                                {getValues("events")?.upcomming?.length > 0 ? (
                                  getValues("events")?.upcomming?.map(
                                    (event, index) => {
                                      return (
                                        <div
                                          className="Overview_card_content_holder mb-4"
                                          key={index}
                                        >
                                          {/* Old Component -------------------------------------------------------------------------------------------------------------- */}

                                          {/* <Overview_card
                                          event={event}
                                          getValues={getValues}
                                          setValue={setValue}
                                          // seteventDetailsId={seteventDetailsId}
                                          // seteventDetails={seteventDetails}
                                          setActive={setActive}
                                          reset={reset}
                                        /> */}

                                          {/* Old Component end-------------------------------------------------------------------------------------------------------------- */}

                                          <section className="Overview_card">
                                            <div className="Overview_card_holder">
                                              <div className="table-container">
                                                <Table
                                                  responsive
                                                  className="match-table mb-0"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        colSpan="2"
                                                        className="text-start"
                                                      >
                                                        {event?.event_name}
                                                      </th>

                                                      <th className="text-center">
                                                        {FormateDateTime(
                                                          event?.event_date
                                                        )}
                                                      </th>
                                                      <th className="text-center"></th>
                                                      <th className="text-center"></th>
                                                      <th className="text-center">
                                                        {event?.id}
                                                      </th>
                                                      <th className="text-center"></th>
                                                      <th
                                                        className="text-center btn"
                                                        onClick={async () => {
                                                          await setActive(
                                                            "second"
                                                          );
                                                          // await setValue("event", event);
                                                          await setValue(
                                                            "event_id",
                                                            event?.id
                                                          );

                                                          reset(getValues());
                                                          // await seteventDetails(event);
                                                          // await seteventDetailsId(event?.id);
                                                          // console.log(event?.id);
                                                        }}
                                                      >
                                                        {event?.status?.replace(
                                                          /_/g,
                                                          " "
                                                        )}
                                                        {/* {status.notstarted ===
                                                        event.status
                                                          ? "Upcomming Event"
                                                          : "Event Details"} */}
                                                      </th>
                                                      <th className="text-center">
                                                        <FontAwesomeIcon
                                                          className="start-icon"
                                                          icon={faStar}
                                                        />
                                                      </th>
                                                    </tr>
                                                  </thead>

                                                  {/* <tbody>
                                                  {market?.map((val, index) => (
                                                    <tr>
                                                      <td className="time-cell">
                                                        {event?.clock?.minutes}:{event?.clock?.seconds}
                                                      </td>
                                                      <td>{val?.description}</td>
                                                      <td className="text-center">
                                                        <FontAwesomeIcon className="play-icon" icon={faPlay} />
                                                      </td>
                                                      <td className="text-center number-box active">1.25</td>
                                                      {val?.outcomes?.map((outcome, index) => (
                                                        <td className="text-center number-box" key={index}>
                                                          {outcome?.consolidatedPrice?.currentPrice?.format}
                                                        </td>
                                                      ))}

                                                      <td className="text-center">0</td>
                                                      <td className="text-center">
                                                        <FontAwesomeIcon className="start-icon" icon={faStar} />
                                                      </td>
                                                    </tr>
                                                  ))}

                                                </tbody> */}
                                                </Table>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <h1>No Upcoming Match Going On</h1>
                                )}
                              </section>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              {/* Old Component -------------------------------------------------------------------------------------------------------------- */}
                              {/* <Event_details
                                getValues={getValues}
                                setValue={setValue}
                                eventDetailsId={eventDetailsId}
                                eventDetails={eventDetails}
                              /> */}
                              {/* Old Component end-------------------------------------------------------------------------------------------------------------- */}

                              <section className="Event_details">
                                <div className="Event_details_content_card mb-4">
                                  {/* Old Component -------------------------------------------------------------------------------------------------------------- */}
                                  {/* <Event_details_card
                                    allEvent={allEvent}
                                    event={event}
                                    market={market}
                                    eventId={eventId}
                                  /> */}
                                  {/* Old Component -------------------------------------------------------------------------------------------------------------- */}

                                  <>
                                    <section className="Event_details_card">
                                      <div className="Event_details_card_holder">
                                        {/* {eventData?.map((value) => ( */}
                                        <>
                                          <section className="details-heading-holder">
                                            <div className="ball-img-holder">
                                              <img
                                                className="ball-img"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Home/Banner/banner-img-1.png"
                                                }
                                                alt="ball-img"
                                              />
                                            </div>

                                            <div className="heading-holder">
                                              <div>
                                                <h4>Live Football</h4>
                                                <h2>{eventData?.event_name}</h2>
                                              </div>
                                            </div>
                                          </section>

                                          <section className="live-list-box-holder">
                                            <div className="game-vdo-score-holder">
                                              <div className="text-holder">
                                                <p>
                                                  {eventData?.opponent_a_name}
                                                </p>
                                              </div>

                                              <div className="text-holder">
                                                <p>
                                                  {
                                                    <>
                                                      <span>
                                                        {" "}
                                                        {`${eventData
                                                          ?.score_info
                                                          ?.team_a_points ||
                                                          0}:${eventData
                                                          ?.score_info
                                                          ?.team_b_points ||
                                                          0}`}
                                                      </span>
                                                    </>
                                                  }
                                                </p>

                                                <p className="time-text">
                                                  {eventData?.clock
                                                    ? JSON.parse(
                                                        eventData.clock
                                                      ).minutes + " min"
                                                    : undefined}
                                                  {/* : */}
                                                  {/* {eventData?.clock
                                                  ? JSON.parse(eventData.clock).seconds + "sec"
                                                  : undefined} */}
                                                  {/* {eventData?.event_minute || 0} min :{" "}
                                                   {eventData?.event_second || 0} sec */}
                                                </p>
                                              </div>

                                              <div className="text-holder">
                                                <p>
                                                  {eventData?.opponent_b_name}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="list-holder">
                                              <div>
                                                <ul>
                                                  {event?.score_info?.map(
                                                    (val, index) => (
                                                      <li>
                                                        {val?.team_a_points} -{" "}
                                                        {val?.period}
                                                      </li>
                                                    )
                                                  )}
                                                  <li>
                                                    {
                                                      eventData?.score_info
                                                        ?.current_period
                                                    }
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </section>
                                        </>
                                        {/* ))} */}
                                        <section className="match-accordian-holder">
                                          <Accordion defaultActiveKey={0}>
                                            {market?.length ? (
                                              market?.map((val, index) => (
                                                <Accordion.Item eventKey={0}>
                                                  <Accordion.Header>
                                                    <p>
                                                      {val?.description} -{" "}
                                                      {
                                                        val?.period
                                                          ?.fullDescription
                                                      }
                                                    </p>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <div className="accordian-content">
                                                      <div className="row">
                                                        {val?.outcomes?.map(
                                                          (outcome, index) => (
                                                            <div
                                                              className="col-md-4 border-right-holder"
                                                              key={index}
                                                            >
                                                              <div className="text-content-holder">
                                                                <p>
                                                                  {
                                                                    outcome?.description
                                                                  }
                                                                </p>

                                                                <div className="time-holder">
                                                                  <p
                                                                    onClick={() => {
                                                                      if (
                                                                        signin
                                                                      ) {
                                                                        addToCart(
                                                                          val?.eventId,
                                                                          val?.id,
                                                                          val?.description,
                                                                          outcome?.id,
                                                                          outcome?.description,
                                                                          outcome
                                                                            ?.consolidatedPrice
                                                                            ?.currentPrice
                                                                            ?.format
                                                                        );
                                                                      } else {
                                                                        setLoginShow(
                                                                          true
                                                                        );
                                                                      }
                                                                    }}
                                                                  >
                                                                    {
                                                                      outcome
                                                                        ?.consolidatedPrice
                                                                        ?.currentPrice
                                                                        ?.format
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              ))
                                            ) : (
                                              <h2>
                                                No open markets for this event.
                                              </h2>
                                            )}
                                          </Accordion>
                                        </section>
                                      </div>
                                    </section>
                                    <Success_Modal
                                      text={"Cart Added Successfully"}
                                      show={modalShow}
                                      onHide={() => setModalShow(false)}
                                    />{" "}
                                    <Login_Modal
                                      show={loginShow}
                                      onHide={() => setLoginShow(false)}
                                      loginModal={() => setLoginShow(true)}
                                    />
                                  </>

                                  {/* <Live_Live_Center_my_Bet_Section market={market} /> */}
                                  {/* {getValues("event_id") == event?.id && (
                                    <Event_details_card event={event} market={market} />
                                  )} */}
                                </div>
                              </section>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </Tab.Container>
                  </div>
                </section>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3   p-0 order-2 order-md-2 ">
              <div className="ex-last-div">
                {/* old */}
                {/* <Live_bet_sidebar /> */}

                <section className="ExchangeBetPlaceSidebar">
                  <div className="min-heading">
                    <p className="title">Live Centre</p>
                    <div class="wrapper">
                      <div class="corners corners--slanted"></div>
                    </div>
                  </div>

                  <Live_Live_Center_tab_Section />

                  <Live_Live_Center_my_Bet_Section />
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Live;
