import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

// export const getSports = async () => {
//   try {
//     return await getData(`/without-login/thired-party/getSports`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getAllLive = async () => {
//   try {
//     return await getData(`/without-login/thired-party/getAllLive`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getEvents = async (id) => {
//   try {
//     return await getData(`/without-login/thired-party/getEvents/${id}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getMarkets = async (id) => {
//   try {
//     return await getData(`/without-login/thired-party/getMarkets/${id}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// -----------------------------------------
export const getAllEvents = async (id) => {
  try {
    return await getData(`/without-login/games/get-all-events`);
  } catch (error) {
    console.log(error);
  }
};

export const getAllLiveEvents = async (id) => {
  try {
    return await getData(`/without-login/games/all-live-events-today`);
  } catch (error) {
    console.log(error);
  }
};

// export const getEventMarket = async (id) => {
//   try {
//     return await getData(`/without-login/games/all-live-event-markets/${id}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getEvent = async (id) => {
  try {
    return await getData(`/without-login/games/get-event/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getEventMarket = async (id) => {
  try {
    return await getData(
      `/without-login/thired-party/get-new-markets?eventId=${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addtoCart = async (data) => {
  try {
    return await postData(`/without-login/games/add-cart`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getAllCart = async () => {
  try {
    return await getData(`/without-login/games/get-cart`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteCart = async (id) => {
  try {
    return await deleteData(`/without-login/games/delete-cart/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const postPlaceBet = async (data) => {
  try {
    return await postData(`/without-login/games/place-bet`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getSingleEventOfCustomer = async (id) => {
  try {
    return await getData(`/without-login/games/single-event/${id}`);
  } catch (error) {
    console.log(error);
  }
};
