import React from "react";
import { Modal } from "react-bootstrap";
import Delete_Lottie from "./Animation/Delete_Lottie/Delete_Lottie";


function Delete_Modal({ text, ...props }) {
  return (
    <>
      <Modal
        {...props}
        className="Success_Modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-content">
            <Delete_Lottie/>

            <div className="text-holder">
              <p className="text">{text}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Delete_Modal;
