import React, { useContext, useState } from "react";
import "./exchangeprofile.css";
import "./exchangeprofile2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Successfull_Lottie from "../../Animation/Successfull_Lottie/Successfull_Lottie";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";

const ChangePassword = () => {
  const { postData, setSignin, Cookies } = useContext(Context);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const [showNote, setShowNote] = useState(false);
  const [currentVisible, setCurrentVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const toggleCurrentVisibility = () => setCurrentVisible(!currentVisible);
  const toggleNewVisibility = () => setNewVisible(!newVisible);
  const toggleConfirmVisibility = () => setConfirmVisible(!confirmVisible);

  const handleClick = () => {
    setShowNote(true);
    setTimeout(() => setShowNote(false), 3000);
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("current_password", data?.current_password);
      formData.append("new_password", data?.new_password);

      const response = await postData(`/auth/change-password`, formData);

      if (response?.success) {
        setShowNote(true);
        reset();
        setTimeout(() => setShowNote(false), 3000);
      } else {
        setError("new_password", {
          type: "manual",
          message: "Failed to update password. Please try again.",
        });
      }
    } catch (error) {
      console.log(error);
      setError("new_password", {
        type: "manual",
        message: "An error occurred while updating the password.",
      });
    }
  };

  return (
    <section className="exchangeprofilemain-sec">
      <div className="secttiill">
        <div className="titlediv">
          <p className="title">Change Password</p>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="Change">
          <div className="Change-Password">
            <div className="row">
              <div className="col-md-6">
                {/* Current Password */}
                <div className="main form-group main mb-lg-5 mb-4">
                  <Form.Control
                    type={currentVisible ? "text" : "password"}
                    placeholder="Enter Current Password*"
                    name="current_password"
                    {...register("current_password", {
                      required: "Current password is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.current_password,
                    })}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={toggleCurrentVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={currentVisible ? faEye : faEyeSlash}
                      style={{ color: "white" }}
                    />
                  </div>
                  {errors?.current_password && (
                    <sup className="text-danger">
                      {errors?.current_password?.message}
                    </sup>
                  )}
                </div>

                {/* New Password */}
                <div className="main form-group main mb-lg-5 mb-4">
                  <Form.Control
                    type={newVisible ? "text" : "password"}
                    placeholder="Enter New Password*"
                    name="new_password"
                    {...register("new_password", {
                      required: "New Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message:
                          "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.new_password,
                    })}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={toggleNewVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={newVisible ? faEye : faEyeSlash}
                      style={{ color: "white" }}
                    />
                  </div>
                  {errors?.new_password && (
                    <sup className="text-danger">
                      {errors?.new_password?.message}
                    </sup>
                  )}
                </div>

                {/* Confirm Password */}
                <div className="main form-group">
                  <Form.Control
                    type={confirmVisible ? "text" : "password"}
                    placeholder="Confirm New Password*"
                    name="confirm_password"
                    {...register("confirm_password", {
                      required: "Confirm password is required",
                      validate: (value) =>
                        value === getValues("new_password") ||
                        "Passwords do not match",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.confirm_password,
                    })}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={toggleConfirmVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={confirmVisible ? faEye : faEyeSlash}
                      style={{ color: "white" }}
                    />
                  </div>
                  {errors?.confirm_password && (
                    <sup className="text-danger">
                      {errors?.confirm_password?.message}
                    </sup>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="password">
            <div className="secttiill">
              <div className="titlediv" onClick={handleSubmit(onSubmit)}>
                <p className="title">Change Password</p>
              </div>
            </div>
            {showNote && (
              <div className="note-main slide-left">
                <div className="note">
                  <div className="note-content">
                    <Successfull_Lottie />
                    <p className="pass">Password Changed Successfully</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Form>
    </section>
  );
};

export default ChangePassword;
