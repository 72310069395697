import React, { useContext, useState, useEffect } from "react";
import "./exchangeprofile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import { getTransactionData } from "../../../../utils/apis/User_details";
import { useForm } from "react-hook-form";
import classNames from "classnames";

const Account_details = () => {
  const { postData, IMG_URL, getData } = useContext(Context);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("account_no", data?.account_no);
      formData.append("ifsc_code", data?.ifsc_code);
      formData.append("upi_id", data?.upi_id);

      if (data?.qr_code && data?.qr_code[0]) {
        formData.append("qr_code", data.qr_code[0]);
      }

      const response = await postData(`/admin/account-details`, formData);

      if (response?.success) {
        // reset();
        setTimeout(() => {
          fetchData();
        }, 1000);
      } else {
        console.error(response?.message || "Error updating account details.");
      }
    } catch (error) {
      console.error("Submission error:", error);
      setError("account_no", {
        type: "manual",
        message: "An error occurred during submission.",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getData(`/admin/get-account-details`);
      reset(res.data); // Pre-fill the form with fetched data
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  };

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, [reset]);

  const imageFile = watch("qr_code");

  return (
    <>
      {/* start desktop view exchangeprofilemain-sec section  */}
      <section className="exchangeprofilemain-sec">
        <div className="secttiill">
          <div className="titlediv">
            <p className="title">Account Details</p>
          </div>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="Change">
            <div className="Change-Password">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="main form-group">
                    <Form.Label>Account No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Account Number"
                      {...register("account_no", {
                        required: "Account Number  is required",
                      })}
                      name="account_no"
                      className={classNames("", {
                        "is-invalid": errors?.account_no,
                      })}
                    />
                  </div>
                  {errors?.account_no && (
                    <sup className="text-danger">
                      {errors?.account_no?.message}
                    </sup>
                  )}
                </div>{" "}
                <div className="col-md-6 mb-4">
                  <div className="main form-group">
                    <Form.Label>IFSC Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter IFSC Code"
                      {...register("ifsc_code", {
                        required: "IFSC Code  is required",
                      })}
                      name="ifsc_code"
                      className={classNames("", {
                        "is-invalid": errors?.ifsc_code,
                      })}
                    />
                  </div>
                  {errors?.ifsc_code && (
                    <sup className="text-danger">
                      {errors?.ifsc_code?.message}
                    </sup>
                  )}
                </div>{" "}
                <div className="col-md-6 mb-4">
                  <div className="main form-group">
                    {" "}
                    <Form.Label>UPI ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter UPI Id"
                      {...register("upi_id", {
                        required: "UPI ID  is required",
                      })}
                      name="upi_id"
                      className={classNames("", {
                        "is-invalid": errors?.upi_id,
                      })}
                    />
                  </div>
                  {errors?.upi_id && (
                    <sup className="text-danger">{errors?.upi_id?.message}</sup>
                  )}
                </div>{" "}
                <div className="col-md-6 mb-4">
                  {/* <Form.Group className="form-field" controlId="formBasicEmail"> */}
                  <Form.Label>Qr Code</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.qr_code,
                    })}
                    type="file"
                    {...register("qr_code", {})}
                    accept=".jpg, .jpeg, .png"
                  />
                  {errors.qr_code && (
                    <span className="text-danger">
                      {errors.qr_code.message}
                    </span>
                  )}
                  {/* </Form.Group> */}
                </div>
                <div className="col-md-12 col-sm-12 mt-3">
                  <Form.Group className="form-field" controlId="formBasicEmail">
                    <Form.Label>QR Preview</Form.Label>
                    {typeof getValues("qr_code") == "string" ? (
                      <div className="image-preview-container mt-3">
                        <img
                          src={IMG_URL + getValues("qr_code")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "648px", height: "198px" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div className="image-preview-container mt-3">
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "648px", height: "198px" }}
                          />
                        </div>
                      )
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className="password">
              <div className="secttiill">
                <div className="titlediv" onClick={handleSubmit(onSubmit)}>
                  <p className="title">Submit</p>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
      {/* end desktop view exchangeprofilemain-sec section  */}

      {/* start exchangeMobView_secion   */}
      <section className="exchangeMobView_secion">
        <div className="toptitlediv">
          <div className="d-flex justify-content-between ">
            <div className="my-auto">
              <h6 className="profttt">
                <Link to="/exchange-profile/profile">
                  <FontAwesomeIcon className="backiccc" icon={faAngleLeft} />
                </Link>
                Account Statement
              </h6>
            </div>

            <div className="my-auto">
              <Form.Select aria-label="Default select example">
                <option>All</option>
                <option value="1">Diposite/Withdraw Report</option>
                <option value="2">Game Report</option>
              </Form.Select>
            </div>

            <div className="my-auto">
              <FontAwesomeIcon className="calendericon" icon={faCalendarDays} />
            </div>
          </div>
        </div>

        <div className="contentdivv">
          <div className="formdiv">
            <div className="text-center mb-4">
              <h6>Pick Date</h6>
            </div>

            <Form>
              <div className="formgroup">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Start Date"
                />
              </div>
              <div className="formgroup">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="End Date"
                />
              </div>
              <div className="formgroup text-center">
                <button className="btn clearbtn">Clear</button>
                <button className="btn submitbtn">Submit</button>
              </div>
            </Form>
          </div>
        </div>

        <div className="statementlist-div"></div>
      </section>
      {/* end exchangeMobView_secion   */}
    </>
  );
};

export default Account_details;
