import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";
import { formatDate } from "../../common";

export const getTransactionData = async (id) => {
  try {
    return await getData(`/auth/transaction-data/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getUserWalletHistory = async (
  currentPage,
  perPage,
  id,
  type,
  from,
  to
) => {
  try {
    return await getData(
      `/admin/users/wallet?page=${currentPage || 1}&per_page=${perPage ||
        10}&id=${id}&term=${type || ""}&from=${
        from ? formatDate(from) : ""
      } &to=${to ? formatDate(to) : ""}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getUserProfitLoss = async (
  currentPage,
  perPage,
  id,
  result,
  odd_name,
  from,
  to
) => {
  try {
    return await getData(
      `/admin/users/user-profit-loss?page=${currentPage ||
        1}&per_page=${perPage || 10}&id=${id}&term=${result ||
        ""}&odd_name=${odd_name || ""}&from=${
        from ? formatDate(from) : ""
      } &to=${to ? formatDate(to) : ""}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getUserBetHistory = async (
  currentPage,
  perPage,
  id,
  event_name,
  search,
  from,
  to
) => {
  try {
    return await getData(
      `/admin/users/bets-history?page=${currentPage || 1}&per_page=${perPage ||
        10}&id=${id}&event_name=${event_name || ""}&term=${search || ""}&from=${
        from ? formatDate(from) : ""
      } &to=${to ? formatDate(to) : ""}`
    );
  } catch (error) {
    console.log(error);
  }
};
