import React, { useContext, useState, useEffect } from "react";
import "./exchangeprofile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faCalendarDays,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import {
  getTransactionData,
  getUserWalletHistory,
} from "../../../../utils/apis/User_details";
import { format } from "date-fns";
import Pagination from "react-bootstrap/Pagination";
import Pagination_Holder from "../../../Common_Component/Pagination_Holder/Pagination_Holder";
import { Table, Button } from "react-bootstrap";
const AccountStatement = () => {
  const { signin, setSignin, Cookies, userdata } = useContext(Context);

  console.log(userdata, "userdata");

  const id = userdata?.id;
  const [show, setShow] = React.useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [eventName, setEventName] = useState("");
  const [oddName, setOddName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [reset, setReset] = useState();
  const [totalPages, settotalPages] = useState();
  const [option, setOption] = useState();
  const [onPageChange, setonPageChange] = useState(1);
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);
  const [type, setType] = useState([]);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const getData = async () => {
    const response = await getUserWalletHistory(
      onPageChange,
      perPage,
      id,
      type,
      from,
      to
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    settotalPages(response?.data?.totalPages);

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getData();
  }, [
    perPage,
    reset,
    onPageChange,
    search,
    eventName,
    oddName,
    firstName,
    userdata,
    type,
    from,
    to,
  ]);

  return (
    <>
      {/* start desktop view exchangeprofilemain-sec section  */}
      <section className="exchangeprofilemain-sec">
        <div className="secttiill">
          <div className="titlediv">
            <p className="title">Account Statement</p>
          </div>
        </div>

        <div className="tablemain-div">
          {" "}
          <div className="row">
            <div className="col-md-3 mb-4">
              <div className="formgroup">
                <Form.Label>Result</Form.Label>
                <Form.Select
                  aria-label="Result"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value="credit">Credit</option>
                  <option value="debit">Debit</option>
                  <option value="withdraw">Withdraw</option>
                </Form.Select>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="formgroup">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Label> From</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    value={from}
                    onChange={(e) => {
                      setFrom(e.target.value);
                      handlePageChange(1);
                      setTo("");
                    }}
                  />
                </Form.Group>
              </div>{" "}
            </div>

            <div className="col-md-3 mb-4">
              <div className="formgroup">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Label> To</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    value={to}
                    onChange={(e) => {
                      setTo(e.target.value);
                      handlePageChange(1);
                    }}
                    min={from}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="col-md-3 my-auto">
              <Button
                type="button"
                className="reset-btn"
                onClick={() => {
                  setSearch("");
                  setEventName("");
                  setFirstName("");
                  setFrom("");
                  setTo("");
                  setType("");
                  setFirstName("");
                  setReset(!reset);
                }}
              >
                Reset
              </Button>
            </div>

          </div>
          <div className="table-div">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="">
                    <th>Sr.No</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Balance</th>
                    <th>Remark</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.data?.data?.length > 0 ? (
                    data?.data?.data?.map((d, index) => {
                      const paginatedIndex =
                        (onPageChange - 1) * perPage + index + 1;
                      return (
                        <tr>
                          <td>{paginatedIndex}</td>

                          <td className="delete-tr">
                            {format(new Date(d?.createdAt), "dd-MM-yyyy HH:mm")}
                          </td>

                          <td
                            className={
                              d?.type === "credit" ? "text-green" : "text-red"
                            }
                          >
                            {d?.type}
                          </td>
                          <td
                            className={
                              d?.type === "credit" ? "text-green" : "text-red"
                            }
                          >
                            {d?.amount}
                          </td>
                          <td
                            className={
                              d?.type === "credit" ? "text-green" : "text-red"
                            }
                          >
                            {d?.remaining_amount}
                          </td>
                          <td>{d?.remark}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <>No Data</>
                  )}
                </tbody>
              </table>{" "}
              <Pagination_Holder
                onPageChange={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
          <div className="tablemain-div account-setting-table">
            {/* <div className="Values">
              <div className="sect tiill">
                <div className="titlediv">
                  <p className="title">Close</p>
                </div>
              </div>
            </div> */}
            {/* <div className="table-div account-setting-table-inner">
              <div className="row">
                <Form>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Matched"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                      />

                      <Form.Check
                        inline
                        label="Deleted"
                        name="group1"
                        type={type}
                        id={`inline-${type}-3`}
                      />
                    </div>
                  ))}
                </Form>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Nation</th>
                      <th>Side</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Win / Loss</th>
                      <th>Place Date</th>
                      <th>Match Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Dragon</td>
                      <td>Back</td>
                      <td>2</td>
                      <td>5000</td>
                      <td>5000</td>
                      <td>8/01/2024 5:58:34 PM</td>
                      <td>8/01/2024 5:58:34 PM</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* end desktop view exchangeprofilemain-sec section  */}

      {/* start exchangeMobView_secion   */}
      <section className="exchangeMobView_secion">
        <div className="toptitlediv">
          <div className="d-flex justify-content-between ">
            <div className="my-auto">
              <h6 className="profttt">
                <Link to="/exchange-profile/profile">
                  <FontAwesomeIcon className="backiccc" icon={faAngleLeft} />
                </Link>
                Account Statement
              </h6>
            </div>

            <div className="my-auto">
              <Form.Select aria-label="Default select example">
                <option>All</option>
                <option value="1">Diposite/Withdraw Report</option>
                <option value="2">Game Report</option>
              </Form.Select>
            </div>

            <div className="my-auto">
              <FontAwesomeIcon className="calendericon" icon={faCalendarDays} />
            </div>
          </div>
        </div>

        <div className="contentdivv">
          <div className="formdiv">
            <div className="text-center mb-4">
              <h6>Pick Date</h6>
            </div>

            <Form>
              <div className="formgroup">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Start Date"
                />
              </div>
              <div className="formgroup">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="End Date"
                />
              </div>
              <div className="formgroup text-center">
                <button className="btn clearbtn">Clear</button>
                <button className="btn submitbtn">Submit</button>
              </div>
            </Form>
          </div>
        </div>

        <div className="statementlist-div"></div>
      </section>
      {/* end exchangeMobView_secion   */}
    </>
  );
};

export default AccountStatement;
