import React, { useContext, useEffect, useState } from "react";

import "./exchangeprofile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import Pagination from "react-bootstrap/Pagination";
import { getUserProfitLoss } from "../../../../utils/apis/User_details";
import Pagination_Holder from "../../../Common_Component/Pagination_Holder/Pagination_Holder";
import { format } from "date-fns";
import { Table, Button } from "react-bootstrap";
const ProfitLoss = () => {
  const { signin, setSignin, Cookies, userdata } = useContext(Context);

  const id = userdata?.id;

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [eventName, setEventName] = useState("");
  const [result, setResult] = useState("");
  const [oddName, setOddName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [reset, setReset] = useState();
  const [totalPages, settotalPages] = useState();
  const [onPageChange, setonPageChange] = useState(1);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const getData = async () => {
    const response = await getUserProfitLoss(
      onPageChange,
      perPage,
      id,
      result,
      oddName,
      from,
      to
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    settotalPages(response?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [
    perPage,
    reset,
    onPageChange,
    search,
    eventName,
    oddName,
    firstName,
    userdata,
    result,
    from,
    to,
  ]);

  return (
    <>
      <section className="exchangeprofilemain-sec">
        <div className="secttiill">
          <div className="titlediv">
            <p className="title">Profit Loss</p>
          </div>
        </div>

        <div className="tablemain-div">
          <div className="row">
            {" "}
            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <Form.Label>Result</Form.Label>
                <Form.Select
                  aria-label="Result"
                  onChange={(e) => setResult(e.target.value)}
                  value={result}
                >
                  <option value="">Select Result</option>
                  <option value="WIN">WIN</option>
                  <option value="LOSE">LOSE</option>
                  <option value="VOID">VOID</option>
                  <option value="LOSE_VOID">LOSE_VOID</option>
                  <option value="WIN_VOID">WIN_VOID</option>
                </Form.Select>
              </div>
            </div>
            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Label> From</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    value={from}
                    onChange={(e) => {
                      setFrom(e.target.value);
                      handlePageChange(1);
                      setTo("");
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Label> To</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    value={to}
                    onChange={(e) => {
                      setTo(e.target.value);
                      handlePageChange(1);
                    }}
                    min={from}
                  />
                </Form.Group>
              </div>
            </div>{" "}

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <Form.Label> Odd Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Odd Name"
                  onChange={(e) => setOddName(e.target.value)}
                  value={oddName}
                />
              </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5    mt-auto">
              <Button
                type="button"
                className="reset-btn"
                onClick={() => {
                  setSearch("");
                  setEventName("");
                  setFirstName("");
                  setFrom("");
                  setTo("");
                  setResult("");
                  setFirstName("");
                  setReset(!reset);
                }}
              >
                Reset
              </Button>
            </div>
            
          </div>

          <div className="table-div">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Result</th>
                    <th>User Name</th>
                    <th>Event Name</th>
                    <th>Market Name</th>
                    <th>Odd Name</th>
                    <th>Odd</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.data?.data?.length > 0 ? (
                    data?.data?.data?.map((d, index) => {
                      const paginatedIndex =
                        (onPageChange - 1) * perPage + index + 1;
                      return (
                        <tr>
                          <td>{paginatedIndex}</td>
                          <td>{d?.result}</td>
                          <td>{d?.user?.user_name}</td>{" "}
                          <td>{d?.bet?.event?.event_name}</td>{" "}
                          <td>{d?.bet?.market_name}</td>{" "}
                          <td>{d?.bet?.odd_name}</td> <td>{d?.bet?.odds}</td>{" "}
                          <td>{d?.bet?.amount}</td>{" "}
                          <td className="border-none-holder">
                            {new Date(d.createdAt).toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            })}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>No Data</>
                  )}
                </tbody>
              </table>{" "}
              <Pagination_Holder
                onPageChange={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfitLoss;
